import { Fragment, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { gaTrackInlineCtaClicks } from 'utils/google-analytics/gaTracking';

import './styles/inline-cta.scss';
import Button from '../buttons/button';
import Vero from '../forms/vero';
import Signup from '../modal/signups';

type InlineCTAProps = {
  id: string
};

const InlineCTA = ({ id }:InlineCTAProps) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressWpInlineCtas {
        edges {
          node {
            id
            wordpress_id
            content
            acf {
              cta_type
              marketo_form_id
              marketo_content_asset
              trial_type
              trial_coupon_code
              cta_button_text
              image_position
              image_alignment
              button_color
              cta_color
              cta_button_alignment
              text_link #gatsby-config.js runs a normalizer to generate this field from link_url
            }
            featured_media {
              source_url
              alt_text
            }
          }
        }
      }
    }
  `);

  const [formType, setFormType] = useState('');
  const [contentDownload, setContentDownload] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [submitBtnText, setSubmitBtnText] = useState('');

  const getForm = cta => {
    switch (cta.acf.cta_type) {
      case 'marketo':
        useEffect(() => {
          cta.acf.cta_button_text ? setSubmitBtnText(cta.acf.cta_button_text) : setSubmitBtnText('Submit');
          if (cta.acf.marketo_form_id === '1156') {
            setFormType('blogSubscription');
          } else if (cta.acf.marketo_form_id === '1157') {
            cta.acf.text_link ? setContentDownload(cta.acf.text_link) : setContentDownload('#');
            cta.acf.marketo_content_asset ? setContentTitle(cta.acf.marketo_content_asset) : setContentTitle('#');
            setFormType('contentDownload');
          } else if (cta.acf.marketo_form_id === '1158') {
            setFormType('newsletterSubscription');
          }
        }, []);
        return (
          <Fragment>
            {formType === 'blogSubscription' && (
              <Vero
                uniqueId={ cta.acf.marketo_content_asset }
                formType={ formType }
                emailField
                contentTitle={ contentTitle }
                submitBtnText={ submitBtnText }
                showIcons
              />
            )}
            {formType === 'newsletterSubscription' && (
              <Vero
                uniqueId={ cta.acf.marketo_content_asset }
                formType={ formType }
                className="news-letter-submission"
                emailField
                contentTitle={ contentTitle }
                submitBtnText={ submitBtnText }
                showIcons
              />
            )}
            {formType === 'contentDownload' && (
              <Vero
                uniqueId={ cta.acf.marketo_content_asset }
                formType={ formType }
                fullNameField
                emailField
                industryField
                employeesField
                contentTitle={ contentTitle }
                downloadUrl={
                  formType === 'contentDownload' ? contentDownload : ''
                }
                submitBtnText={ submitBtnText }
                showIcons
              />
            )}
          </Fragment>
        );
      case 'trial':
        return (
          <div className={ cta.acf.cta_button_alignment }>
            <Signup
              buttonClasses="button is-medium is-success"
              signupModalLaunch={ cta.acf.cta_button_text }
              showModalButton
            />
          </div>
        );
      case 'link':
        return (
          <div className={ cta.acf.cta_button_alignment }>
            <Button
              type="link"
              href={ cta.acf.text_link }
              className="button is-medium is-success"
              text={ cta.acf.cta_button_text }
            />
          </div>
        );
      //return other form types here!!!!
    }
  };

  const getImage = cta => {
    if (
      cta.featured_media &&
      typeof cta.featured_media.source_url !== 'undefined'
    ) {
      return (
        <div className="cta-image-wrapper column">
          <img
            loading="lazy"
            src={ cta.featured_media.source_url }
            alt={ cta.featured_media.alt_text }
          />
        </div>
      );
    }
    return '';
  };

  const getCTA = () => {
    const ctas = data.allWordpressWpInlineCtas.edges;
    let i = ctas.length;

    while (i--) {
      if (ctas[i].node.wordpress_id === parseInt(id)) {
        return ctas[i].node;
      }
    }
  };

  const recordClick = event => {
    if (event.target.tagName.toLowerCase() === 'button') {
      gaTrackInlineCtaClicks(id, window.location.href);
    }
  };

  const cta = getCTA();

  return (
    <div
      className={ `inline-cta-section cta-breakout img-${cta.acf.image_position} type-${cta.acf.cta_type} color-${cta.acf.cta_color}` }
      onClick={ event => recordClick(event) }
    >
      <div className="cta-content is-vcentered columns">
        <div className="cta-form-wrapper column">
          {cta.content.length > 0 && (
            <div
              className="cta-copy"
              dangerouslySetInnerHTML={ { __html: cta.content } }
            ></div>
          )}
          {getForm(cta)}
        </div>
        {getImage(cta)}
      </div>
    </div>
  );
};

export default InlineCTA;