import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';

import './styles/downloads.scss';
import CloseBtn from '../buttons/button-close';
import Vero from '../forms/vero';

ReactModal.setAppElement('#___gatsby');

type ContentDownloadProps = {
  className: string,
  contentCopy: string,
  contentTitle: string,
  downloadUrl: string
  showIcons: boolean,
  submitBtnText: string
}

const ContentDownload = ({ 
  contentTitle,
  contentCopy,
  className,
  downloadUrl,
  submitBtnText,
  showIcons,
}:ContentDownloadProps) => {
  const [showModal, hideModal] = useModal(({ onExited }) => (
    <ReactModal
      isOpen
      onAfterClose={ onExited }
      onRequestClose={ hideModal }
      className="default-modal-wrapper downloads-modal"
      overlayClassName="default-modal-overlay"
    >
      <section
        className="modal-inner-container"
        data-section="contentDownloadModal"
      >
        <CloseBtn onClick={ hideModal } />
        <h2 className="has-text-weight-light is-size-3 has-text-centered has-margin-bottom-md">
          {contentTitle}
        </h2>
        <p style={ { margin: '0 1em 2rem 1em' } }>{contentCopy}</p>
        <Vero
          formType="contentDownload"
          contentTitle={ contentTitle }
          fullNameField
          emailField
          industryField
          employeesField
          downloadUrl={ downloadUrl }
          uniqueId="-vero-contentDownload"
          submitBtnText={ submitBtnText }
          showIcons={ showIcons }
        />
      </section>
    </ReactModal>
  ));
  // eslint-disable-next-line
  return (
    <button className={ className && className } type="button" onClick={ showModal }>
      {submitBtnText}
    </button>
  );
};

export default ContentDownload;
