import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/blog';

import InlineCTA from 'components/content-blocks/inline-cta';
import Yoast from 'components/globals/yoast';
import ContentDownload from 'components/modal/downloads';
import SchedulingSignup from 'components/modal/signups';

import './styles/employee-scheduling-excel-template.scss';


const EmployeeSchedulingExcelTemplate = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: { slug: { eq: "employee-scheduling-excel-template" } }
      ) {
        edges {
          node {
            wordpress_id
            yoast_head_json {
              title
              description
              canonical
              og_title
              og_description
              og_image {
                url {
                  source_url
                }
              }
              og_url
              og_type
              twitter_title
              twitter_description
              twitter_image {
                source_url
              }
            }
            acf {
              hero {
                title
                copy
                vero {
                  button_text
                  modal_title
                  modal_copy
                  modal_submit_button_text
                  content_download_url {
                    source_url
                  }
                }
                background_desktop_image {
                  source_url
                }
              }
              cards_1 {
                header {
                  title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                }
              }
              media_object {
                body
                title
                sub_title
                desktop_image {
                  alt_text
                  source_url
                }
                button_text
              }
              basic_title_subtitle_copy_image_2 {
                wysiwyg
              }
              faq {
                answer
                question
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node;
  const hero = pageContext.acf.hero;
  const cards1 = pageContext.acf.cards_1;
  const media_object = pageContext.acf.media_object[0];
  const wysiwyg = pageContext.acf.basic_title_subtitle_copy_image_2.wysiwyg;
  const faq = pageContext.acf.faq;

  return (
    <Layout>
      <Yoast
        { ...pageContext.yoast_head_json }
        canonical={ `https://${process.env.GATSBY_SITE_URL}/blog/employee-scheduling-excel-template` }
      />
      <div className="eset-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        {hero && (
          <section className="eset-hero-section"
            data-section="heroDownloadTemplate"
            style={ {
              backgroundImage: `url(${hero.background_desktop_image.source_url})`,
            } }
          >
            <div className="container">
              <h1 className="title has-text-centered">{hero.title}</h1>
              <p className="copy has-text-centered">{hero.copy}</p>
              <ContentDownload
                downloadUrl={ hero.vero.content_download_url.source_url }
                contentTitle={ hero.vero.modal_title }
                submitBtnText={ hero.vero.modal_submit_button_text }
                showIcons={ false }
                className="button is-medium is-hidden-mobile is-rounded"
                contentCopy={ hero.vero.modal_copy }
              />
            </div>
          </section>
        )}
        {cards1 && (
          <section className="eset-steps-section section">
            <div className="container">
              <h2 className="title has-text-centered">{cards1.header.title}</h2>
              <div className="columns">
                {cards1.card.map(card => (
                  <div key={ card.header } className="column">
                    <h3 className="has-text-centered">
                      <span>{card.header}</span>
                      {card.title}
                    </h3>
                    <img src={ card.image.source_url } alt={ card.title } />
                    <p>{card.copy}</p>
                  </div>
                ))}
              </div>
              <p className="copy">{cards1.header.copy}</p>
            </div>
          </section>
        )}
        <section className="eset-signup-section" data-section="signupCta">
          <div className="container">
            <div
              className="columns"
              style={ {
                backgroundImage: `url(${media_object.desktop_image.source_url})`,
              } }
            >
              <div className="column is-half is-offset-6">
                <h2 className="title">{media_object.title}</h2>
                <h3>{media_object.sub_title.toUpperCase()}</h3>
                <p>{media_object.body}</p>
                <SchedulingSignup
                  modalTitle="Start your free trial"
                  buttonClasses="button is-rounded"
                  showInitFields={ false }
                  uniqueId="signupsFooter"
                  fieldLayout="row"
                  buttonInline
                  signupModalLaunch={ media_object.button_text }
                  showModalButton
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section addtional-content">
          <div className="container" dangerouslySetInnerHTML={ { __html: wysiwyg } } />
        </section>
        <section className="cta-section">
          <InlineCTA id="12638" />
        </section>
      </div>
    </Layout>
  );
};
export default EmployeeSchedulingExcelTemplate;
